import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <header>
      <div className="logo">
        <NavLink to="/">preddz design</NavLink>
      </div>
      <ul className="burger">
        <li>
          <NavLink to="/info">info</NavLink>
        </li>
      </ul>
    </header>
  );
};

export default Header;
