import { useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { useSpring, a } from "@react-spring/three";

const DoDec = ({ position, color, args }) => {
  const doDecMesh = useRef(null);
  useFrame(() => {
    doDecMesh.current.rotation.x = doDecMesh.current.rotation.y += 0.003;
  });

  const [expand, setExpand] = useState(false);

  const props = useSpring({
    scale: expand ? [1.8, 1.8, 1.8] : [1, 1, 1],
  });

  return (
    <a.mesh
      position={position}
      ref={doDecMesh}
      onClick={() => setExpand(!expand)}
      scale={props.scale}
      className="mybox"
    >
      <dodecahedronBufferGeometry attach="geometry" />
      <meshBasicMaterial wireframe color={color} />
    </a.mesh>
  );
};

/* const BoxShape = ({ position, color, args }) => {
  const shapeMesh = useRef(null);
  useFrame(() => {
    shapeMesh.current.rotation.x = shapeMesh.current.rotation.y += 0.003;
  });

  const [expand, setExpand] = useState(false);

  const props = useSpring({
    scale: expand ? [1.8, 1.8, 1.8] : [1, 1, 1],
  });

  return (
    <a.mesh
      position={position}
      ref={shapeMesh}
      onClick={() => setExpand(!expand)}
      scale={props.scale}
      className="mybox"
    >
      <boxBufferGeometry attach="geometry" args={args} />
      <meshBasicMaterial wireframe color={color} />
    </a.mesh>
  );
}; */

const Box = () => {
  return (
    <>
      {/* <BoxShape
        position={[-2.3, 0.7, -8]}
        color={"#fe0b8f"}
        args={[0.4, 0.4, 0.4]}
      />
      <BoxShape
        position={[2.5, 0.2, -8]}
        color={"#606060"}
        args={[0.2, 0.2, 0.2]}
      /> */}
      {/* <DoDec position={[-2.3, 0.7, -8]} color={"#fe0b8f"} />
      <DoDec position={[2.5, 0.2, -8]} color={"#606060"} /> */}
      {/* <DoDec position={[2, -0.5, -9]} color={"#606060"} /> */}
      <DoDec position={[-3, 1.5, -9]} color={"#606060"} />
      <DoDec position={[3, 1.5, -9]} color={"#606060"} />
    </>
  );
};

export default Box;
