import { useRef, Suspense, useEffect, useState } from "react";
import * as THREE from "three";
import { useFrame, useLoader } from "@react-three/fiber";
import { Plane } from "@react-three/drei";

import terrain from "../img/terrain.jpg";
import height from "../img/height.png";
import alpha from "../img/alpha.png";

const Terrain = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const terrainMesh = useRef(null);
  const terrainMat = useRef(null);
  useFrame(() => (terrainMesh.current.rotation.z += 0.002));

  document.addEventListener("mousemove", animateTerrain);
  let mouseY = 0;

  function animateTerrain(event) {
    mouseY = event.clientY;
  }

  useFrame(() => {
    if (width >= 768) {
      terrainMat.current.displacementScale = 1.7 + mouseY * 0.0009;
    }
  });

  const theheight = useLoader(THREE.TextureLoader, height);
  const normals = useLoader(THREE.TextureLoader, terrain);
  const thealpha = useLoader(THREE.TextureLoader, alpha);
  return (
    <group>
      <Plane
        args={[3, 3, 64, 64]}
        rotation={[-Math.PI / 2, 0, 8]}
        position={[0, -1, 0]}
        ref={terrainMesh}
      >
        <meshStandardMaterial
          ref={terrainMat}
          attach="material"
          color="white"
          map={normals}
          //metalness={0.2}
          displacementMap={theheight}
          displacementScale={2}
          alphaMap={thealpha}
          transparent={true}
          depthTest={false}
        />
      </Plane>
    </group>
  );
};

const Mountain = () => {
  return (
    <>
      <pointLight intensity={8} color={"#fe0b8f"} position={[2, 10, 0.4]} />
      <Suspense fallback={null}>
        <Terrain />
      </Suspense>
    </>
  );
};

export default Mountain;
