import { useRef } from "react";

import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

const particlesGeometry = new THREE.BufferGeometry();
const particlesCnt = 3000;
const posArray = new Float32Array(particlesCnt * 3);

for (let i = 0; i < particlesCnt * 3; i++) {
  posArray[i] = (Math.random() - 0.5) * 5;
}

particlesGeometry.setAttribute(
  "position",
  new THREE.BufferAttribute(posArray, 3)
);

const particlesMaterial = new THREE.PointsMaterial({
  size: 0.02,
});

const ParticlesMesh = () => {
  const particlesMesh = useRef(null);

  useFrame(() => {
    particlesMesh.current.rotation.x = particlesMesh.current.rotation.y +=
      -0.0001;
  });

  return (
    <points
      ref={particlesMesh}
      geometry={particlesGeometry}
      material={particlesMaterial}
    />
  );
};

const BackgroundParticles = () => {
  return (
    <>
      <ParticlesMesh />
    </>
  );
};

export default BackgroundParticles;
