import { motion } from "framer-motion";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Cursor from "../components/Cursor";
import Slides from "../components/Slides";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Autoplay]);

const Home = () => {
  const transVariants = {
    initial: {
      x: "0%",
    },
    animate: {
      x: "-100%",
      transition: {
        delay: 0.5,
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    exit: {
      x: "0%",
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const pageVariants = {
    initial: {
      opacity: 0,
      //scale: 0, // ORIG SCALE ANIM
    },
    animate: {
      opacity: 1,
      //scale: 1, // ORIG SCALE ANIM
      transition: {
        delay: 1.1,
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <>
      <Header />
      <motion.div
        className="trasn"
        variants={transVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      ></motion.div>
      <motion.div variants={pageVariants} initial="initial" animate="animate">
        <Slides />
      </motion.div>
      <Footer />
      <Cursor />
    </>
  );
};

export default Home;
