import "./App.scss";
import { useState, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Box from "./components/Box";
import Mountain from "./components/Mountain";
import BackgroundParticles from "./components/BackgroundParticles";
import { Canvas } from "@react-three/fiber";
import { ScaleLoader } from "react-spinners";

import Home from "./pages/Home";
import Info from "./pages/Info";

function App() {
  const location = useLocation();

  /* let [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }); */

  let [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {loading === true ? (
        <div className="loader-container">
          <ScaleLoader
            height={200}
            width={8}
            margin={5}
            radius={0}
            color="#000"
          />
        </div>
      ) : (
        <>
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.key}>
              <Route path="/" exact component={Home} />
              <Route path="/info" exact component={Info} />
            </Switch>
          </AnimatePresence>
          <div className="webgl">
            <Canvas
              camera={{
                fov: 15,
              }}
            >
              <ambientLight intensity={0.3} />
              <Box />
              <Mountain />
              <BackgroundParticles />
            </Canvas>
          </div>
        </>
      )}
    </>
  );
}

export default App;
