import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "./Image";

import chf from "../img/chf.png";
import chfwp from "../img/chf.webp";
import rkm from "../img/rkm.png";
import rkmwp from "../img/rkm.webp";
import ll from "../img/ll.png";
import llwp from "../img/ll.webp";
import ja from "../img/ja.png";
import jawp from "../img/ja.webp";
import rof from "../img/rof.png";
import rofwp from "../img/rof.webp";
import hh from "../img/hh.png";
import hhwp from "../img/hh.webp";
import lt from "../img/lt.png";
import ltwp from "../img/lt.webp";
import logo from "../img/logo.png";
import logowp from "../img/logo.webp";
import icit from "../img/icit.png";
import icitwp from "../img/icit.webp";
import rbt from "../img/rbt1.png";
import rbtwp from "../img/rbt1.webp";
//import rbtto from "../img/rbt2.png";
//import rbttowp from "../img/rbt2.webp";
import fgmw from "../img/fgmw1.png";
import fgmwwp from "../img/fgmw1.webp";
//import fgmwto from "../img/fgmw2.png";
//import fgmwtowp from "../img/fgmw2.webp";

const portSlides = [
  {
    id: 1,
    title: "Rustik by Tved",
    url: "https://rustikbytved.dk/",
    linktitle: "rustikbytved.dk",
    imagePng: rbt,
    imageWebp: rbtwp,
  },
  {
    id: 2,
    title: "FotoGruppen MidtWest",
    url: "https://fgmidtwest.dk/",
    linktitle: "fgmidtwest.dk",
    imagePng: fgmw,
    imageWebp: fgmwwp,
  },
  {
    id: 3,
    title: "Camilla Holm Fysio",
    url: "https://camillaholmfysio.dk/",
    linktitle: "camillaholmfysio.dk",
    imagePng: chf,
    imageWebp: chfwp,
  },
  {
    id: 4,
    title: "Rækker Mølle Hallen",
    url: "https://rkmhallen.dk/",
    linktitle: "rkmhallen.dk",
    imagePng: rkm,
    imageWebp: rkmwp,
  },
  {
    id: 5,
    title: "Lowlands Denmark",
    url: "https://lowlands.dk/",
    linktitle: "lowlands.dk",
    imagePng: ll,
    imageWebp: llwp,
  },
  {
    id: 6,
    title: "juhl & agger",
    url: "https://juhl-agger.dk/",
    linktitle: "juhl-agger.dk",
    imagePng: ja,
    imageWebp: jawp,
  },
  {
    id: 7,
    title: "Rundt om Fjorden",
    url: "https://rundtomfjorden.dk/",
    linktitle: "rundtomfjorden.dk",
    imagePng: rof,
    imageWebp: rofwp,
  },
  {
    id: 8,
    title: "Massør Hanne Holm",
    url: "https://massoerhanneholm.dk/",
    linktitle: "massoerhanneholm.dk",
    imagePng: hh,
    imageWebp: hhwp,
  },
  {
    id: 9,
    title: "La Toscana",
    url: "https://la-toscana.dk/",
    linktitle: "la-toscana.dk",
    imagePng: lt,
    imageWebp: ltwp,
  },
  {
    id: 10,
    title: "Logo Design",
    url: "#",
    linktitle: "Logo Design",
    imagePng: logo,
    imageWebp: logowp,
  },
  {
    id: 11,
    title: "Tarm Idrætscenter",
    url: "https://icit.dk/",
    linktitle: "icit.dk",
    imagePng: icit,
    imageWebp: icitwp,
  },
];

const slides = () => {
  const buttonVariants = {
    hover: {
      scale: 1.3,
      transition: {
        ease: "easeInOut",
      },
    },
  };

  return (
    <Swiper
      slidesPerView={1}
      navigation
      pagination={{ type: "progressbar" }}
      autoplay={{ delay: 4500 }}
    >
      {portSlides.map((slide) => (
        <SwiperSlide key={slide.id}>
          <InView threshold={0.2}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                className="content"
                initial={{
                  opacity: 0,
                  //scale: 0 // ORIG SCALE ANIM
                }}
                animate={
                  inView
                    ? {
                        opacity: 1,
                        //scale: 1, // ORIG SCALE ANIM
                        transition: {
                          //duration: 0.5, // ORIG SCALE ANIM
                          duration: 0.6,
                          ease: "easeInOut",
                        },
                      }
                    : {
                        opacity: 0,
                        //scale: 0 // ORIG SCALE ANIM
                      }
                }
              >
                <div className="port-title">
                  <h1>{slide.title}</h1>
                </div>
                <div className="port-img">
                  <Image
                    src={slide.imageWebp}
                    fallback={slide.imagePng}
                    alt={slide.linktitle}
                  />
                </div>
                <motion.button variants={buttonVariants} whileHover="hover">
                  <a
                    href={slide.url}
                    target="_blank"
                    rel="noreferrer"
                    title={slide.linktitle}
                  >
                    {slide.linktitle}
                  </a>
                </motion.button>
              </motion.div>
            )}
          </InView>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default slides;
