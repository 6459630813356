import { motion } from "framer-motion";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Cursor from "../components/Cursor";

const Info = () => {
  const transVariants = {
    initial: {
      x: "0%",
    },
    animate: {
      x: "-100%",
      transition: {
        delay: 0.5,
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    exit: {
      x: "0%",
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const containerVariants = {
    hidden: {
      opacity: 0,
      x: "100",
      transition: {
        staggerChildren: 0.2,
      },
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 1,
        type: "spring",
        mass: 0.4,
        damping: 8,
        staggerChildren: 0.2,
        when: "beforeChildren",
      },
    },
  };

  const childVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  return (
    <>
      <Header />
      <motion.div
        className="trasn"
        variants={transVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      ></motion.div>
      <motion.div
        className="info-content"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        //exit="exit"
      >
        <div>
          <h1>Steven Thomas Preddey</h1>

          <h2>Web-udvikler / webdesigner / grafiker</h2>
          <motion.ul className="info-list">
            <motion.li variants={childVariants}>
              <strong>Uddannelse:</strong> Associate degree i Multimedie -
              Southern Cross universitetet i Coffs Harbour, Australien.
            </motion.li>
            <motion.li variants={childVariants}>
              <strong>Skills:</strong> Web Development, Web Design, UI Design,
              Logo Design, Print Design, Illustration, Video Editing,
              Photography, Motion Graphics.
            </motion.li>
            <motion.li variants={childVariants}>
              <strong>Programmering:</strong> HTML, CSS, JavaScript, Frontend
              Frameworks, SASS, Git, GSAP, Framer Motion, React Spring,
              three.js, styled-components, Tailwind CSS, Bootstrap, WordPress,
              WooCommerce, PHP.
            </motion.li>
            <motion.li variants={childVariants}>
              <strong>Software:</strong> Photoshop, Illustrator, InDesign, XD,
              Premiere Pro, After Effects, Visual Studio Code, Procreate,
              Lightroom, Final Cut Pro, Office, C5.
            </motion.li>
            <motion.li variants={childVariants}>
              <strong>Kontakt:</strong> Tlf. 50536807. Email:
              <a href="mailto:info@preddzdesign.dk">info@preddzdesign.dk</a>
              <br />
              CVR: 32798209.
            </motion.li>
          </motion.ul>
        </div>
      </motion.div>
      <Footer />
      <Cursor />
    </>
  );
};

export default Info;
