const Footer = () => {
  return (
    <footer>
      <div className="footer-contact">
        <p>
          <a href="mailto:info@preddzdesign.dk">info@preddzdesign.dk</a>
        </p>
      </div>
      <div className="footer-copy">
        <p>&copy; 2021</p>
      </div>
    </footer>
  );
};

export default Footer;
